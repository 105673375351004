import React from 'react';
import './titleSection.css';
interface TitleSectionProps {
    text: string
}

const TitleSection = ({ text }: TitleSectionProps) => {
    return (
        <div className={"title-container"}>
            <span className="title">{text}</span>
        </div>
    );
};

export default TitleSection;