import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Gallery.css"; // Importamos el archivo CSS personalizado

import imagen3 from '../../assets/images/IMAGEN3.jpg';
import imagen4 from '../../assets/images/IMAGEN4.jpg';
import imagen5 from '../../assets/images/IMAGEN5.jpg';
import imagen6 from '../../assets/images/IMAGEN6.jpg';
import imagen7 from '../../assets/images/IMAGEN7.jpeg';
import imagen8 from '../../assets/images/IMAGEN8.jpg';
import imagen9 from '../../assets/images/IMAGEN9.jpg';
import imagen10 from '../../assets/images/IMAGEN10.jpg';
import imagen11 from '../../assets/images/IMAGEN11.jpg';
import imagen12 from '../../assets/images/IMAGEN12.jpg';
import imagen13 from '../../assets/images/IMAGEN13.jpg';

const images = [
  {
    original: imagen3,
    thumbnail: imagen3,
  },
  {
    original: imagen4,
    thumbnail: imagen4,
  },
  {
    original: imagen5,
    thumbnail: imagen5,
  },
  {
    original: imagen6,
    thumbnail: imagen6,
  },
  {
    original: imagen7,
    thumbnail: imagen7,
  },
  {
    original: imagen8,
    thumbnail: imagen8,
  },
  {
    original: imagen9,
    thumbnail: imagen9,
  },
  {
    original: imagen10,
    thumbnail: imagen10,
  },
  {
    original: imagen11,
    thumbnail: imagen11,
  },
  {
    original: imagen12,
    thumbnail: imagen12,
  },
  {
    original: imagen13,
    thumbnail: imagen13,
  },
];

export class MyGallery extends Component {
  state = {
    key: 0, // Utilizamos la clave para forzar el remount
  };

  handleScreenChange = () => {
    if (!document.fullscreenElement) {
      // Al salir del fullscreen, cambiamos la clave del componente para desmontarlo y volver a montarlo
      this.setState({ key: this.state.key + 1 });
    }
  };

  componentDidMount() {
    document.addEventListener("fullscreenchange", this.handleScreenChange);
  }

  componentWillUnmount() {
    document.removeEventListener("fullscreenchange", this.handleScreenChange);
  }

  render() {
    return (
      <div className="gallery-container">
        {/* Cambiamos la clave para forzar el remount */}
        <ImageGallery
          key={this.state.key}
          items={images}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={true}
          thumbnailPosition="bottom"
          showNav={true}
        />
      </div>
    );
  }
}

export default MyGallery;
