import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import logo from '../../assets/images/logo.png';
import './navbar.css'; // Si quieres mantener estilos adicionales
import { Box, ListItemButton } from '@mui/material';

function Navbar() {
  const [navBackground, setNavBackground] = useState('transparent');
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    const sections = ['inicio', 'somos', 'servicios'];
    const currentSection = sections.find(section => {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.top < window.innerHeight;
      }
      return false;
    });

    if (currentSection) {
      setActiveSection(currentSection);
    }

    setNavBackground(window.scrollY > 50 ? '#ced5dd' : 'transparent'); // Cambia el fondo al hacer scroll
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = (sectionId: string) => {
    scrollToSection(sectionId);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    toggleMenu();
  };

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: navBackground, transition: 'background-color 0.3s ease' }}>
        <Toolbar>
          <img src={logo} alt="Logo" className="logo" style={{ marginRight: 'auto' }} />
         
          <Box className="desktop-sections"
            sx={{
              display: { xs: 'none', md: 'flex' } // Esto aplicará 'none' en pantallas pequeñas (xs) y 'flex' en pantallas medianas o más grandes (md)
            }}
          >
            <Button color="inherit" onClick={() => handleButtonClick('inicio')} className={activeSection === 'inicio' ? 'active' : ''}>INICIO</Button>
            <Button color="inherit" onClick={() => handleButtonClick('somos')} className={activeSection === 'somos' ? 'active' : ''}>¿QUIÉNES SOMOS?</Button>
            <Button color="inherit" onClick={() => handleButtonClick('servicios')} className={activeSection === 'servicios' ? 'active' : ''}>NUESTROS SERVICIOS</Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={isMenuOpen} onClose={toggleMenu}>
        <List>
          <ListItemButton onClick={() => handleButtonClick('inicio')}>
            <ListItemText primary="INICIO" />
          </ListItemButton>
          <ListItemButton onClick={() => handleButtonClick('somos')}>
            <ListItemText primary="¿QUIÉNES SOMOS?" />
          </ListItemButton>
          <ListItemButton onClick={() => handleButtonClick('servicios')}>
            <ListItemText primary="NUESTROS SERVICIOS" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}

export default Navbar;
