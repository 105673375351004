import React from 'react';
import './servicesSection.css';
import viaje from '../../assets/images/viaje.png';
import recorrido from '../../assets/images/recorrido.png';
import seguridad from '../../assets/images/seguridad.png';
import bloqueo from '../../assets/images/bloqueo.png';
import cocina from '../../assets/images/cocina.png';
import garantia from '../../assets/images/garantia.png';
import TitleSection from '../../components/TitleSection/titleSection';
import ServiceList from '../../components/ServiceList/ServiceList';
import { MyGallery } from '../../components/Gallery/Gallery';
const services = [
  {
    id: "service-1",
    image: viaje,
    description: "Con nuestra camper, tienes la libertad de explorar sin restricciones.<strong> No estás atado a horarios de check-in o check-out</strong> de hoteles, ni limitado por rutas de transporte público. Puedes cambiar tu itinerario sobre la marcha, deteniéndote en cualquier lugar que despierte tu interés.",
  },
  {
    id: "service-2",
    image: recorrido,
    description: "Nuestra camper es <strong>nueva y cuenta con pocos kilómetros recorridos</strong>, garantizando una experiencia de viaje segura y fiable. Te beneficiarás de la última tecnología y comodidades modernas, asegu- rando un viaje sin contratiempos.",
  },

  {
    id: "service-3",
    image: seguridad,
    description: "Nuestra camper está equipada con <strong>sistemas de seguridad avanzados, GPS y asistencia en carretera</strong>, para que puedas viajar con tranquilidad sabiendo que estás en buenas manos.",
  },
  {
    id: "service-4",
    image: bloqueo,
    description: "Disfruta de la tranquilidad de poder <strong>cancelar tu reserva de forma gratuita</strong>, excepto durante los meses de temporada alta. En temporada alta, aplican políticas de cancelación especiales. Se deberá consultar en el formulario de inscripción.",
  },
  {
    id: "service-5",
    image: cocina,
    description: "<strong>Cocina Completa</strong>: Equipamiento de cocina moderno, que incluye nevera y todos los utensilios necesarios para preparar tus comidas favoritas.<br /> <strong>Dormitorio Acogedor</strong>: Cama cómoda de 1’80m asegurando un buen descanso después de un día de exploración.<br /> <strong>Baño Privado</strong>: Un baño con ducha, lavabo e inodoro.",
  },

  {
    id: "service-6",
    image: garantia,
    description: "Para garantizar la seguridad y el buen uso de la camper,<strong> solicitamos una fianza de 500€</strong>. Este depósito se devuelve íntegramente al finalizar el alquiler, una vez se haya comprobado que la autocaravana se encuentra en las mismas condiciones en las que fue entregada.",
  },

];

function ServiceSection() {
  return (
    <section className="services-section">
      <TitleSection text='¡Explora las fotografías!' />
      <MyGallery/>
      <ServiceList services={services}></ServiceList>
    </section>
  );
}

export default ServiceSection;
