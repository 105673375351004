import React, { useEffect } from 'react';
import './exploraSection.css';
import explora from '../../assets/images/explora.jpg';
import MainButton from '../../components/MainButton';
import TitleSection from '../../components/TitleSection/titleSection';



function ExploraSection() {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const handleScroll = () => {
    const element = document.getElementById('reservar');
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight) {
      }
    }
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <section className="container">
      <div className="text-section">
      <TitleSection text='¡Viaja a tu aire!' />
        <p>En <strong>ALQUILER DE CAMPERS</strong>, compartimos tu pasión por la aventura:</p>
        <ul>
          <li><span className="icon">➤</span>Experiencias inolvidables en carretera, brindando el servicio de alquiler de campers <strong>totalmente equipadas</strong>.</li>
          <li><span className="icon">➤</span>Nuestro propósito es <strong>facilitarte la escapada perfecta</strong>.</li>
          <li><span className="icon">➤</span><strong>Fotografías</strong> de nuestra camper para conocer sus características.</li>
        </ul>
        <p><strong>¿Listo para empezar tu viaje?</strong></p>
        <MainButton onClick={handleWhatsAppClick}>RESERVAR</MainButton>
      </div>
      <div className="image-section">
        <img src={explora} alt="Imagen de la camper en la naturaleza" />
      </div>
    </section>

  );
}

export default ExploraSection;
