import React from 'react';
import './index.css';

interface MainButtonProps {
  onClick: () => void;
  isSubmit?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
}

const MainButton: React.FC<MainButtonProps> = ({ onClick, isSubmit, isDisabled, children }) => {
  return (
    <button type={isSubmit === false ? "submit": 'button' } disabled={isDisabled} onClick={onClick} className="neon-button">
      {children}
    </button>
  );
}

export default MainButton;
