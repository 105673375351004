import { useEffect } from 'react';
import './introSection.css';
import MainButton from '../../components/MainButton';

function IntroSection() {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const handleScroll = () => {
    const element = document.getElementById('reservar');
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight) {
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank', 'noopener,noreferrer');
  };
  return (
    <section className="intro-section">
      <div className='intro-container'>
        <div className='intro-title-container'>
          <h1>Descubre la libertad de viajar sin límites</h1>
          
        </div>
        <MainButton onClick={() => handleWhatsAppClick()}>RESERVAR</MainButton>
      </div>
    
    </section>
  );
}

export default IntroSection;
