import React from 'react';
import './serviceList.css';

interface Service {
    id: string;
    image: string;
    description: string;
}

interface ServiceListProps {
    services: Service[];
}

const ServiceList: React.FC<ServiceListProps> = ({ services }) => {
    return (
        <div className="services-grid">
            {services.map(service => (
                <div key={service.id} className="service">
                    <img src={service.image} alt={"Servicio"} />
                    <p className="italic" dangerouslySetInnerHTML={{ __html: service.description }}></p>
                </div>
            ))}
        </div>
    );
};

export default ServiceList;
