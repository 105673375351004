import React from 'react';
import './footer.css';
import logo from '../../assets/images/logo.png'
import politicaPrivacidadPDF from '../../assets/pdf/Alquiler de Campers | Política de Privacidad.pdf';

const footerData = {
  columns: [
    {
      title: "OFERTA DE PRODUCTO",
      links: ["Reservar"],
    },
    {
      title: "EMPRESA",
      links: ["¿Quiénes somos?", "Nuestros servicios"],
    },
    {
      title: "CLIENTES",
      links: ["Contacto", "Información legal"],
    },
  ],
  mobile: [{
    links: ["Información legal"],
  }
  ]
};
const isMobile = window.innerWidth <= 480;
function Footer() {
  const handleLinkClick = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Logo" style={{ width: '150px' }} />
          <p>Alquiler de Campers</p>
        </div>
        {isMobile ?
          <div className="footer-columns">
            {footerData.mobile.map((column, index) => (
              <div key={index} className="footer-column">
                <ul>
                  {column.links.map((link, linkIndex) => {
                    let sectionId = '';
                    let isPDFLink = false;
                    if (link === "Información legal") isPDFLink = true;
                    return (
                      <li key={linkIndex}>
                        {isPDFLink ? (
                          <a href={politicaPrivacidadPDF} target="_blank" rel="noopener noreferrer">{link}</a>
                        ) : (
                          <a href={`#${sectionId}`} onClick={() => handleLinkClick(sectionId)}>{link}</a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
          :
          <div className="footer-columns">
            {footerData.columns.map((column, index) => (
              <div key={index} className="footer-column">
                <h4>{column.title}</h4>
                <ul>
                  {column.links.map((link, linkIndex) => {
                    let sectionId = '';
                    let isPDFLink = false;
                    if (link === "¿Quiénes somos?") sectionId = 'quienes-somos';
                    else if (link === "Nuestros servicios") sectionId = 'servicios';
                    else if (link === "Planes") sectionId = 'planes';
                    else if (link === "Información legal") isPDFLink = true;
                    return (
                      <li key={linkIndex}>
                        {isPDFLink ? (
                          <a href={politicaPrivacidadPDF} target="_blank" rel="noopener noreferrer">{link}</a>
                        ) : (
                          <a href={`#${sectionId}`} onClick={() => handleLinkClick(sectionId)}>{link}</a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        }
      </div>
    </footer>
  );
}

export default Footer;
