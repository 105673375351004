import './App.css';
import Footer from './components/Footer/Footer';
import IntroSection from './sections/IntroSection/IntroSection';
import ServiceSections from './sections/ServicesSection/ServicesSection';
import Navbar from './components/Navbar/Navbar';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import Suscription from './sections/Suscription/Suscription';
import ExploraSection from './sections/ExploraSection/ExploraSection';

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <section id="inicio"><IntroSection /></section>
        <section id="servicios"><ExploraSection /></section>
        <section id="servicios"><ServiceSections /></section>
       
      </main>


      <Footer />
    </div>
  );
}

export default App;
